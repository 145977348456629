<template>
	<div class="page">
		<div class="view">
			<div class="viewT">
				<div style="font-weight: bold;">套餐信息</div>
				<el-button style="width: 110px;" @click="handleBackClick">返回</el-button>
			</div>
			<div class="viewC">
				<!-- 基本信息 -->
				<div class="viewTitle">基本信息</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName">条形码:</div>
						<el-input v-model="barCode" placeholder="请输入条形码" style="width: 200px;" @blur="handelBarCodeBlur"
							clearable :disabled="barCodeAbled"></el-input>
					</div>
					<el-button type="primary" style="margin-left: 12px;" @click="handleAddBarCodeClick"
						v-if="editMsg == null">生成条形码</el-button>
					<el-button style="margin-left: 12px;" @click="handleCancelCode"
						v-if="barCodeAbled && editMsg == null">取消生成</el-button>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName"><span>*</span>套餐名称:</div>
						<el-input v-model="title" placeholder="请输入套餐名称" style="width: 200px;" @input="handleInput"></el-input>
					</div>
					<div class="comItem">
						<div class="comItemName">简码:</div>
						<el-input v-model="simpleCode" placeholder="请输入简码" style="width: 200px;"
							:disabled="simpleCodeAbled"></el-input>
						<el-tooltip class="item" effect="dark"
							content="例:如佩玛思特，简码设置为pm就可以直接搜索到这个商品，一个商品可多个简码，输入框顿号分隔。如佩玛思特为pm、st、pmst" placement="bottom">
							<i class="el-icon-question" style="color: #F77E04;margin-left: 10px;font-size: 20px;"></i>
						</el-tooltip>
					</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName"><span>*</span>套餐分类:</div>
						<el-select v-model="firstClass" placeholder="请选择一级分类" style="width: 200px;" clearable
							@change="handleFirstClassChange">
							<el-option v-for="item in firstClassList.itemList" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
						<el-select v-model="secondClass" placeholder="请选择二级分类" style="width: 200px;margin-left: 12px;"
							clearable v-if="firstClass != ''">
							<el-option v-for="item in secondClassList.itemList" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
					<div class="comTxt">(没找到分类?请<span @click="handleAddClassClick()">新增分类</span>)</div>
				</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName"><span>*</span>适用品种:</div>
						<el-radio-group v-model="applyType">
							<el-radio :label="'common'">通用</el-radio>
							<el-radio :label="'cat'">猫猫</el-radio>
							<el-radio :label="'dog'">狗狗</el-radio>
							<el-radio :label="'yichong'">其他</el-radio>
						</el-radio-group>
					</div>
				</div>
				<!-- 售卖信息 -->
				<div class="viewTitle">售卖信息</div>
				<div class="comView">
					<div class="comItem">
						<div class="comItemName">套餐价:</div>
						<el-input type="number" v-model.number="salePrice" placeholder="请输入零售价" style="width: 200px;">
						</el-input>
					</div>
				</div>
				<!-- 套餐商品 -->
				<div style="display: flex;align-items: center;margin-bottom: 20px;">
					<div class="viewTitle" style="margin-bottom: 0;">套餐商品</div>
					<el-button style="width: 110px;margin-left: 20px;" @click="dialogProduct_state = true">添加商品</el-button>
				</div>
				
				<el-table :data="tableData">
					<el-table-column prop="newData.productName" label="商品名称">
					</el-table-column>
					<el-table-column prop="newData.brand" label="品牌">
					</el-table-column>
					<el-table-column prop="newData.modelValue" label="规格">
					</el-table-column>
					<el-table-column prop="newData.originalPrice" label="零售价">
					</el-table-column>
					<el-table-column label="数量">
						<template slot-scope="scope">
							<el-input-number v-model="scope.row.newData.num" :step="1" size="small"></el-input-number>
						</template>
					</el-table-column>
					<el-table-column prop="address" label="总计">
						<template slot-scope="scope">
							{{(scope.row.newData.num*scope.row.newData.originalPrice)*1000/1000}}
						</template>
					</el-table-column>
					<el-table-column label="操作" width="80">
						<template slot-scope="scope">
							<el-button type="text" size="small" @click="handleDeleteClick(scope.$index)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>

			<div class="viewB">
				<el-button style="width: 110px;" v-if="editMsg != null"  @click="printCustom(barCode)">打印条码</el-button>
				<el-button type="primary" style="width: 110px;margin-right: 20px;" @click="handleSubmitClick">提交
				</el-button>
			</div>
		</div>
		<div v-if="dialogTypeSetting_state">
			<dialogTypeSetting @submit="submit_dialogTypeSetting" :addType="addType" :nodeData="nodeData">
			</dialogTypeSetting>
		</div>
		<div v-if="dialogProduct_state">
			<dialogProduct @submit="submit_dialogProduct" :tree="tree" :selectedList="tableData"></dialogProduct>
		</div>
	</div>
</template>

<script>
import { pinyin } from 'pinyin-pro';
import printJs from "../../../util/print.js";
	import dialogTypeSetting from "../../../components/dialogTypeSetting.vue"
	import dialogProduct from "../../../components/dialogProduct.vue"
	export default {
		components: {
			dialogTypeSetting,
			dialogProduct
		},
		data() {
			return {
				editMsg: JSON.parse(this.$route.query.editMsg), //为null是新增  编辑跳转信息
				barCode: '', //条码
				barCodeAbled: false, //条码是否禁填
				title: '', //套餐名称
				simpleCode: '', //简码
				simpleCodeAbled: false, //简码是否禁用
				firstClass: '', //一级分类
				firstClassList: {}, //一级分类列表
				secondClass: '', //二级分类
				secondClassList: {}, //二级分类列表
				applyType: 'common', //适用品种
				salePrice: '', //零售价
				addType: 0, //弹框类型
				nodeData: {}, //弹框内容
				dialogTypeSetting_state: false, //弹框状态
				tableData:[],  //添加商品表格
				dialogProduct_state:false,  //添加商品弹框
				tree:[{code:'goodstype'}],  //商品分类
				
			}
		},
		mounted() {
	
			this.getProductData()
			if (this.editMsg != null) {
				this.barCode = this.editMsg.barCode;
				this.barCodeAbled = true;
				this.title = this.editMsg.title;
				this.simpleCode = this.editMsg.simpleCode;
				this.simpleCodeAbled = true;
				this.applyType = this.editMsg.applyType.id;
				this.salePrice = this.editMsg.salePrice;
				this.firstClass = this.editMsg.firstCategoryInfo.id;
				this.secondClass = this.editMsg.secondCategoryInfo.id;
				this.tableData = this.editMsg.groupItems;
			}
		},
		methods: {
			printCustom() {
			let params = {
				code: this.barCode,
          name: this.title,
          sku:this.model,
          price:this.salePrice,
			};
			printJs.printTicketBarCode(params).then((res) => {
				this.$store.commit("set_printUrl", res);
				var hostObject = window.chrome.webview.hostObjects.customWebView2HostObject;
         hostObject.print(res);

			});

		},
			handleInput(event){
				console.log(event)
				this.simpleCode=this.toPinyin(event).replace(/\s+/g, '')
			},
			toPinyin(str){
				let pinyinArr=pinyin(str,{ pattern: 'initial' })
				return pinyinArr
			},
			//删除商品
			handleDeleteClick(index){
				let list = [...this.tableData];
				list.splice(index, 1); 
				this.tableData = list;
			},
			// 返回产品
			submit_dialogProduct(obj){
				this.tableData = obj.data;
				this.dialogProduct_state = false;
			},
			//提交
			handleSubmitClick() {
				if (this.barCode == '') {
					this.$message({
						type: 'error',
						message: '请输入条形码'
					});
					return
				}
				if (this.title == '') {
					this.$message({
						type: 'error',
						message: '请输入套餐名称'
					});
					return
				}
				if (this.firstClass == '') {
					this.$message({
						type: 'error',
						message: '请选择一级分类'
					});
					return
				}
				if (this.secondClass == '') {
					this.$message({
						type: 'error',
						message: '请选择二级分类'
					});
					return
				}
				
				if(this.tableData.length == 0){
					this.$message({
						type: 'error',
						message: '请添加商品'
					});
					return
				}

				let params = {
					barCode: this.barCode,
					applyType: this.applyType + '',
					title: this.title,
					simpleCode: this.simpleCode,
				}
				//获取1级类目参数
				this.firstClassList.itemList.map((item) => {
					if (item.id == this.firstClass) {
						params.firstCategoryInfo = {
							id: item.id,
							name: item.name,
							code: item.code
						}
					}
				})
				//获取2级类目参数
				this.secondClassList.itemList.map((item) => {
					if (item.id == this.secondClass) {
						params.secondCategoryInfo = {
							id: item.id,
							name: item.name,
							code: item.code
						}
					}
				})
				// 零售价
				if (this.salePrice == '') {
					params.salePrice = 0
				} else {
					params.salePrice = this.salePrice
				}
				
				let goodsList = [];
				this.tableData.map((item)=>{
					goodsList.push({
						goodsId:item.newData.productId,
						count:item.newData.num
					})
				})
				params.groupItems = goodsList;
				
				if (this.editMsg != null) { //编辑
					params.groupId = this.editMsg.groupId;
				}
				this.$http.post("/product/goods/group/update", params).then(res => {
					if (res.code === 0) {
						this.$message({
							type: 'success',
							message: '已提交'
						});
						sessionStorage.setItem('mark',1)
						this.$router.go(-1);
					}
				})
			},
			//1级类目变化
			handleFirstClassChange() {
				this.secondClass = '';
				this.secondClassList = [];
				this.firstClassList.itemList.map((item) => {
					if (item.id == this.firstClass) {
						this.secondClassList = item
					}
				})
			},
			//获取类目
			getProductData() {
				let params = {
					code: 'combotype'
				}
				this.$http.get("/cms/System/GetSystemDiction", params).then(res => {
					if (res.code === 0) {
						this.firstClassList = res.data;
						if (this.firstClass != '') {
							this.firstClassList.itemList.map((item) => {
								if (item.id == this.firstClass) {
									this.secondClassList = item
								}
							})
						}
					}
				})
			},
			//关闭弹框
			submit_dialogTypeSetting() {
				if (this.addType == 1) {
					this.getProductData()
				} else if (this.addType == 2) {
					this.getProductData();
				} else if (this.addType == 5) {
					this.getSupplierData()
				}
				this.dialogTypeSetting_state = false;
			},
			//打开弹框
			handleAddClassClick() {
				if (this.firstClass == '') {
					this.addType = 1;
					this.nodeData = this.firstClassList;
				} else {
					this.addType = 2;
					this.nodeData = this.secondClassList;
				}
				this.dialogTypeSetting_state = true;
			},
			//取消生成条码
			handleCancelCode() {
				this.barCode = '';
				this.barCodeAbled = false;
			},
			//生成条码
			handleAddBarCodeClick() {
				this.barCode = '';
				this.barCodeAbled = false;
				this.$http.post("/product/goods/group/code/new", {
					barcode: this.barCode
				}).then(res => {
					if (res.code === 0) {
						this.$message({
							type: 'success',
							message: '已生成条码'
						});
						this.barCode = res.data;
						this.barCodeAbled = true;
					}
				})
			},
			//条码框失去焦点
			handelBarCodeBlur() {
				if (this.barCode != '') {
					this.$http.get("/product/goods/code/standard/info", {
						barcode: this.barCode
					}).then(res => {
						if (res.code === 0) {
							if (res.data.barCode == null) {
								this.$message({
									type: 'error',
									message: '该条码无信息，请生成条码'
								});
								// this.barCode = '';
							} else {
								this.title = res.data.title;
							}
						}
					})
				}
			},
			//返回
			handleBackClick() {
				this.$confirm('是否返回上一页?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		width: 100%;
		height: 100%;
		color: $fontColor;

		.view {
			background: #fff;
			height: 100%;

			border-radius: 20px;
			display: flex;
			flex-direction: column;

			.viewT {
				box-sizing: border-box;
				height: 68px;
				display: flex;
				align-items: center;
				border-bottom: solid 1px #F2F2F2;
				padding: 0 20px;
				justify-content: space-between;
			}

			.viewC {
				flex: 1;
				overflow-y: scroll;
				padding: 0 20px;
				padding-top: 20px;

				.viewTitle {
					padding-left: 9px;
					font-size: 16px;
					font-weight: bold;
					background: url(../../../assets/img/cangku/detailName.png) no-repeat;
					background-position: left top;
					background-size: 16px 16px;
					margin-bottom: 20px;
				}

				.comView {
					display: flex;
					align-items: center;
					margin-bottom: 20px;
					font-size: 14px;

					.comItem {
						display: flex;
						align-items: center;
						height: 40px;

						.comItemName {
							width: 70px;
							text-align: right;
							margin-right: 8px;

							span {
								color: #FF3939;
							}
						}
					}

					.comTxt {
						color: #666;
						margin-left: 12px;

						span {
							color: #F77E04;
							cursor: pointer;
						}
					}
				}
			}

			.viewB {
				height: 68px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				box-sizing: border-box;
				border-top: solid 1px #F2F2F2;
			}

			.viewC::-webkit-scrollbar {
				display: none;
			}
		}
	}
</style>
